@import '../../styles/common';

.error {
  align-items: flex-start;

  @include desktop {
    align-items: center;
  }
}

.container {
  position: relative;
}

h1.h1 {
  font-size: inherit;
  gap: 8px;
  margin-top: 32px;

  @include desktop {
    gap: 32px;
    margin-top: 0;
  }
}

.statusCode {
  @include desktop {
    font-size: 80px;
  }
}

.title {
  font-size: 30px;
  line-height: 120%;

  @include desktop {
    font-size: 42px;
    max-width: 478px;
  }
}

.desc {
  max-width: 486px;
}

.button {
  margin-top: 16px;
  max-width: 327px;

  @include desktop {
    margin-top: 48px;
  }
}

.fireIcon {
  bottom: 0px;
  height: 299px;
  left: 24px;
  position: absolute;
  width: 184px;

  @include desktop {
    height: 767px;
    left: auto;
    right: 100px;
    width: 472px;
  }
}
